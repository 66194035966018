
import React from "react"
import Helmet from 'react-helmet';
import { Layout } from "../components/Layout/Layout";

const NotFoundPage = () => (
  <Layout>
    <Helmet>
      <title>Terms and Conditions - Devwares </title>
      <meta name="title" content="Terms and Conditions - Devwares" />
      <meta name="description" content="Terms and Conditions for Devwares" />
      <meta property="og:title" content="Terms and Conditions - Devwares" />
      <meta property="og:description" content="Terms and Conditions for Devwares" />
      <meta property="twitter:title" content="Terms and Conditions - Devwares" />
      <meta property="twitter:description" content="Terms and Conditions for Devwares" />
      <link rel="canonical" href="https://www.devwares.com/terms-and-conditions" />
      <meta property="og:url" content="https://www.devwares.com/terms-and-conditions" />
      <meta property="og:site_name" content="Devwares" />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
    <div id="TOS" className="m-24">
      <div className="flex flex-col justify-center items-center">
        <h1 className="font-extrabold text-4xl">Terms and Conditions</h1>
        <p className="m-4 font-medium text-2xl">Last updated: November 2021 </p>
        <br />
        <br />
      </div>
      <p className="my-4">
        These Terms and Conditions ("Terms", "Terms and Conditions") govern your relationship with{' '}
        <href href="https://www.devwares.com">the website</href> (the "Service") operated by
        Devwares ("us", "we", or "our").
      </p>
      <p className="my-2 font-medium">
        Please read these Terms and Conditions carefully before using the Service.
      </p>
      <p className="my-2">
        Your access to and use of the Service is conditioned on your acceptance of and compliance
        with these Terms. These Terms apply to all visitors, users and others who access or use the
        Service.
      </p>
      <p className="my-2">
        By accessing or using the Service you agree to be bound by these Terms. If you disagree with
        any part of the terms then you may not access the Service.
      </p>
      <h2 className="font-bold text-2xl my-4 underline">Subscriptions</h2>
      <p className="my-2">
        Some parts of the Service are billed on a subscription basis ("Subscription(s)"). You will
        be billed in advance on a recurring and periodic basis ("Billing Cycle"). Billing cycles are
        set either on a monthly or annual basis, depending on the type of subscription plan you
        select when purchasing a Subscription.
      </p>
      <p className="my-2">
        At the end of each Billing Cycle, your Subscription will automatically renew under the exact
        same conditions unless you cancel it or Devwares cancels it. You may cancel your
        Subscription renewal either through your online account management page or by contacting
        Devwares customer support team.
      </p>
      <p className="my-2">
        A valid payment method, including credit card or PayPal, is required to process the payment
        for your Subscription. You shall provide Devwares with accurate and complete billing
        information including full name, address, state, zip code, telephone number, and a valid
        payment method information. By submitting such payment information, you automatically
        authorize Devwares to charge all Subscription fees incurred through your account to any such
        payment instruments. Our current Payment Processor is Stripe, and your payments are
        processed by Stripe in accordance with Stripe’s terms of service and Terms and Conditions.
      </p>
      <p className="my-2">
        Should automatic billing fail to occur for any reason, Devwares will issue an electronic
        invoice indicating that you must proceed manually, within a certain deadline date, with the
        full payment corresponding to the billing period as indicated on the invoice.
      </p>
      <h2 className="my-4 font-bold text-2xl underline">Fee Changes</h2>
      <p className="my-2">
        Devwares, in its sole discretion and at any time, may modify the Subscription fees for the
        Subscriptions. Any Subscription fee change will become effective at the end of the
        then-current Billing Cycle.
      </p>
      <p className="my-2">
        Devwares will provide you with a reasonable prior notice of any change in Subscription fees
        to give you an opportunity to terminate your Subscription before such change becomes
        effective.
      </p>
      <p className="my-2">
        Your continued use of the Service after the Subscription fee change comes into effect
        constitutes your agreement to pay the modified Subscription fee amount.
      </p>
      <h2 className="my-4 font-bold text-2xl underline">Refunds</h2>
      <p>
        Certain refund requests for Subscriptions may be considered by Devwares on a case-by-case
        basis and granted in sole discretion of Devwares.
      </p>
      <h2 className="my-4 font-bold text-2xl underline">Content</h2>
      <p className="my-2">
        Our Service allows you to post, link, store, share and otherwise make available certain
        information, text, graphics, videos, or other material ("Content"). You are responsible for
        the Content that you post to the Service, including its legality, reliability, and
        appropriateness.
      </p>
      <p className="my-2">
        By posting Content to the Service, you grant us the right and license to use, modify,
        perform, display, reproduce, and distribute such Content on and through the Service. You
        retain any and all of your rights to any Content you submit, post or display on or through
        the Service and you are responsible for protecting those rights.
      </p>
      <p className="my-2">
        You represent and warrant that:{' '}
        <ul>
          <li className="list-decimal my-2">
            {' '}
            the Content is yours (you own it) or you have the right to use it and grant us the
            rights and license as provided in these Terms, and{' '}
          </li>
          <li className="my-2 list-decimal">
            the posting of your Content on or through the Service does not violate the privacy
            rights, publicity rights, copyrights, contract rights or any other rights of any person.
          </li>
        </ul>
      </p>
      <h2 className="my-4 text-2xl font-bold underline">Accounts</h2>
      <p>
        When you create an account with us, you must provide us information that is accurate,
        complete, and current at all times. Failure to do so constitutes a breach of the Terms,
        which may result in immediate termination of your account on our Service.
      </p>
      <p className="my-2">
        You are responsible for safeguarding the password that you use to access the Service and for
        any activities or actions under your password, whether your password is with our Service or
        a third-party service.
      </p>
      <p>
        You agree not to disclose your password to any third party. You must notify us immediately
        upon becoming aware of any breach of security or unauthorized use of your account.
      </p>
      <h2 className="text-2xl font-bold my-4 underline">Copyright Policy</h2>
      <p>
        We respect the intellectual property rights of others. It is our policy to respond to any
        claim that Content posted on the Service infringes the copyright or other intellectual
        property infringement ("Infringement") of any person.
      </p>
      <p className="my-2">
        If you are a copyright owner, or authorized on behalf of one, and you believe that the
        copyrighted work has been copied in a way that constitutes copyright infringement that is
        taking place through the Service, you must submit your notice in writing to the attention of
        "Copyright Infringement" of contact@devwares.com and include in your notice a detailed
        description of the alleged Infringement.
      </p>
      <p>
        You may be held accountable for damages (including costs and attorneys' fees) for
        misrepresenting that any Content is infringing your copyright.
      </p>
      <h2 className="font-bold text-2xl my-4 underline">Links To Other Web Sites</h2>
      <p>
        Our Service may contain links to third-party web sites or services that are not owned or
        controlled by Devwares.
      </p>
      <p className="my-2">
        Devwares has no control over, and assumes no responsibility for, the content, privacy
        policies, or practices of any third party web sites or services. You further acknowledge and
        agree that Devwares shall not be responsible or liable, directly or indirectly, for any
        damage or loss caused or alleged to be caused by or in connection with use of or reliance on
        any such content, goods or services available on or through any such web sites or services.
      </p>
      <p>
        We strongly advise you to read the terms and conditions and privacy policies of any
        third-party web sites or services that you visit.
      </p>
      <h2 className="text-2xl font-bold my-4 underline">Termination</h2>
      <p>
        We may terminate or suspend your account immediately, without prior notice or liability, for
        any reason whatsoever, including without limitation if you breach the Terms.
      </p>
      <p className="my-2">
        Upon termination, your right to use the Service will immediately cease. If you wish to
        terminate your account, you may simply discontinue using the Service.
      </p>
      <h2 className="my-4 font-bold text-2xl underline">Limitation Of Liability</h2>
      <p className="my-2">
        In no event shall Devwares, nor its directors, employees, partners, agents, suppliers, or
        affiliates, be liable for any indirect, incidental, special, consequential or punitive
        damages, including without limitation, loss of profits, data, use, goodwill, or other
        intangible losses, resulting from (i) your access to or use of or inability to access or use
        the Service; (ii) any conduct or content of any third party on the Service; (iii) any
        content obtained from the Service; and (iv) unauthorized access, use or alteration of your
        transmissions or content, whether based on warranty, contract, tort (including negligence)
        or any other legal theory, whether or not we have been informed of the possibility of such
        damage, and even if a remedy set forth herein is found to have failed of its essential
        purpose.
      </p>
      <h2 className="my-4 font-bold text-2xl underline">Disclaimer</h2>
      <p>
        Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and "AS
        AVAILABLE" basis. The Service is provided without warranties of any kind, whether express or
        implied, including, but not limited to, implied warranties of merchantability, fitness for a
        particular purpose, non-infringement or course of performance.
      </p>
      <p className="my-2">
        Devwares its subsidiaries, affiliates, and its licensors do not warrant that a) the Service
        will function uninterrupted, secure or available at any particular time or location; b) any
        errors or defects will be corrected; c) the Service is free of viruses or other harmful
        components; or d) the results of using the Service will meet your requirements.
      </p>
      <h2 className="my-4 font-bold text-2xl underline">Governing Law</h2>
      <p>
        These Terms shall be governed and construed in accordance with the laws of Connecticut,
        United States, without regard to its conflict of law provisions.
      </p>
      <p className="my-2">
        Our failure to enforce any right or provision of these Terms will not be considered a waiver
        of those rights. If any provision of these Terms is held to be invalid or unenforceable by a
        court, the remaining provisions of these Terms will remain in effect. These Terms constitute
        the entire agreement between us regarding our Service, and supersede and replace any prior
        agreements we might have between us regarding the Service.
      </p>
      <h2 className="font-bold text-2xl my-4 underline">Changes</h2>
      <p>
        We reserve the right, at our sole discretion, to modify or replace these Terms at any time.
        If a revision is material we will try to provide at least 30 days notice prior to any new
        terms taking effect. What constitutes a material change will be determined at our sole
        discretion.
      </p>
      <p className="my-2">
        By continuing to access or use our Service after those revisions become effective, you agree
        to be bound by the revised terms. If you do not agree to the new terms, please stop using
        the Service.
      </p>
      <h2 className="my-4 font-bold text-2xl underline">Contact Us</h2>
      <p>
        If you have any questions about these Terms, please{' '}
        <a href="https://www.devwares.com/contact">contact us</a>.
      </p>
    </div>
  </Layout>
);

export default NotFoundPage